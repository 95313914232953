import React from 'react'
import { cn } from 'lib/utils'
import Image from 'next/image'
import { FACEBOOK_URL, INSTAGRAM_URL } from 'constants/common'
import ExternalLink from 'components/common/ExternalLink'
import Heading from 'components/landing-page/Heading'
import { buttonVariants } from 'components/ui/button'

const StayTuned = () => (
  <div className="flex-1 flex flex-col items-center justify-center lg:py-24 py-10 lg:px-24 md:px-16 sm:px-12 px-8 text-brand-mint bg-brand-slate overflow-hidden">
    <div className="self-center flex xl:flex-row flex-col items-center justify-center w-full xl:gap-20">
      <div className="max-xl:mb-10 w-full max-w-[600px] relative xl:h-[230px] sm:h-[215px] h-[158px]">
        <Image fill src="/images/stay-tuned.png" className="object-contain" alt="Offseason logo" />
      </div>
      <div className="lg:max-w-[635px] xl:px-12 px-8">
        <div className="flex flex-col gap-4">
          <Heading className="text-3xl">Stay tuned</Heading>
          <div className="flex flex-row items-center justify-center text-left text-xl text-slate-300">
            <div className="flex-1 relative leading-normal ">
              Stay in the loop with the latest updates from our community of college athletes.
              Follow us for exclusive content, training tips, and more. Join our growing family and
              be part of the conversation that&apos;s shaping the future of athletic training.
            </div>
          </div>
        </div>
        <div className="flex gap-6 mt-8">
          <ExternalLink
            className={cn(
              buttonVariants({ colorType: 'mint', size: '2xl' }),
              'self-start uppercase text-[18px] font-bold',
            )}
            href={FACEBOOK_URL}
          >
            Facebook
          </ExternalLink>
          <ExternalLink
            className={cn(
              buttonVariants({ colorType: 'mint', size: '2xl' }),
              'self-start uppercase text-[18px] font-bold',
            )}
            href={INSTAGRAM_URL}
          >
            Instagram
          </ExternalLink>
        </div>
      </div>
    </div>
  </div>
)

export default StayTuned

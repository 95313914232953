import * as React from 'react'
import { cn } from 'lib/utils'
import Image from 'next/image'
import Link from 'next/link'
import { Route } from 'constants/common/routes'
import {
  CaliforniaAthleticsBadge,
  FresnoPacificAthleticsBadge,
  FresnoStateAthleticsBadge,
  SanDiegoStateAthleticsBadge,
  WyomingAthleticsBadge,
} from 'components/icons'
import { NevadaAthleticsBadge } from 'components/icons/NevadaAthleticsBadge'
import { buttonVariants } from 'components/ui/button'

export default function Hero() {
  return (
    <div className="bg-radial-gradient relative h-full z-10 overflow-hidden">
      <div className="flex flex-col 2xl:flex-row max-md:items-stretch max-md:gap-0 overflow-hidden">
        <div className="flex flex-col items-stretch 2xl:pb-[130px] xl:pb-[100px] 2xl:px-[158px] lg:px-[93px] md:px-[77px] sm:px-[71px] px-[46.7px] xl:pt-[91px] md:pt-[88px] sm:pt-[78px] pt-[66px]">
          <div className="items-stretch flex flex-col 2xl:max-w-full">
            <h1 className="text-white uppercase max-md:max-w-full text-[44px] sm:text-[56px] md:text-[68px] lg:text-[84px] 2xl:text-[112px] leading-[normal] font-prohibition">
              Success is built
              <br />
              in the Offseason
            </h1>
            <div className="xl:mt-[30px] mt-5 xl:py-2 xl:px-1 max-md:max-w-full xl:text-2xl text-xl leading-normal">
              <h2 className="text-white">
                Book Local College Athletes for Position Specific Training.
              </h2>
              <div className="text-brand-mint uppercase font-prohibition">
                Every sport. Every position.
              </div>
            </div>

            <div className="justify-start items-stretch flex gap-4 mt-12 self-start flex-wrap z-10">
              <Link
                href={Route.CreateAccount}
                className={cn(
                  buttonVariants({ variant: 'outline', size: '2xl' }),
                  'uppercase px-6 w-[189px]',
                )}
              >
                Become a coach
              </Link>
              <Link
                href={Route.Athletes}
                className={cn(
                  buttonVariants({ colorType: 'mint', size: '2xl' }),
                  'text-[18px] font-bold w-[192px] uppercase',
                )}
              >
                Book athlete
              </Link>
            </div>
            <div className="flex flex-col w-full gap-6 px-0.5 xl:mt-[86px] lg:mt-[88px] md:mt-24 sm:mt-20 mt-12">
              <div className="text-white font-prohibition text-[2rem]">partners</div>
              <div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-7 gap-[14px] w-fit">
                <a target="_blank" rel="noreferrer" href="http://gobulldogs.com">
                  <div className="flex">
                    <FresnoStateAthleticsBadge className="h-auto" />
                  </div>
                </a>
                <a target="_blank" rel="noreferrer" href="http://nevadawolfpack.com">
                  <div className="flex">
                    <NevadaAthleticsBadge className="h-auto" />
                  </div>
                </a>
                <a target="_blank" rel="noreferrer" href="http://gowyo.com">
                  <div className="flex">
                    <WyomingAthleticsBadge className="h-auto" />
                  </div>
                </a>
                <a target="_blank" rel="noreferrer" href="http://calbears.com">
                  <div className="flex">
                    <CaliforniaAthleticsBadge className="h-auto" />
                  </div>
                </a>
                <a target="_blank" rel="noreferrer" href="https://goaztecs.com/">
                  <div className="flex">
                    <SanDiegoStateAthleticsBadge className="h-auto" />
                  </div>
                </a>
                <a target="_blank" rel="noreferrer" href="https://www.fpuathletics.com">
                  <div className="flex">
                    <FresnoPacificAthleticsBadge className="h-auto" />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-end justify-end lg:ml-auto xl:ml-0 w-full translate-x-6  5xl:w-[47%] xl:w-[59%] lg:w-[80%] -z-10 aspect-[525/409] xl:absolute 5xl:right-[6%] right-0 bottom-0">
          <Image className="object-contain" fill src="/images/hero-image.png" alt="Athlete image" />
        </div>
      </div>
    </div>
  )
}

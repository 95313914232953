import React from 'react'
import { cn } from 'lib/utils'
import Image from 'next/image'
import BackgroundCaption from 'components/landing-page/BackgroundCaption'
import { HOW_IT_WORKS_SECTION_ID } from 'components/landing-page/constants'
import Heading from 'components/landing-page/Heading'

const HowItWorks = () => (
  <div
    id={HOW_IT_WORKS_SECTION_ID}
    className="z-20 relative flex-1 flex flex-col items-center justify-center py-[88px] lg:px-24 md:px-16 sm:px-12 px-8 text-brand-mint bg-brand-slate overflow-visible"
  >
    <div className="self-stretch flex xl:flex-row flex-col items-center justify-center">
      <div className="max-xl:mb-10 w-full max-w-[600px] relative sm:h-[426px] h-[289px]">
        <Image fill src="/images/perfect-match.png" className="object-contain" alt="Athlete card" />
      </div>
      <div className="flex flex-col gap-4 lg:px-12">
        <Heading prefix="1.">FIND YOUR perfect match</Heading>
        <div className="flex flex-row items-center justify-center text-left text-xl text-slate-300 xl:max-w-[584px] lg:max-w-[738px]">
          <div className="flex-1 relative leading-normal">
            Select from our curated list of dedicated college athletes. Customize your search based
            on your training preferences, including location, sport, position, and cost.
          </div>
        </div>
      </div>
    </div>
    <BackgroundCaption
      className={cn(
        'xl:text-[152px] lg:text-[116px] sm:text-[92px] text-[64px]',
        'xl:-bottom-8 lg:-bottom-24 sm:-bottom-16 -bottom-8 right-0',
      )}
    >
      UP YOUR GAME
    </BackgroundCaption>
  </div>
)

export default HowItWorks

import React from 'react'
import { getAthleteDatasources } from 'api/integrations/storyblok/datasources/getAthleteDatasources'
import { NotificationBarQuery } from 'api/integrations/storyblok/generated/cms'
import { getFeaturedAthletes } from 'api/services/athleteService/getAthletes'
import { Athlete } from 'apollo/generated/graphqlClient'
import { GetServerSideProps, InferGetServerSidePropsType } from 'next'
import Head from 'next/head'
import { getSession } from 'next-auth/react'
import About from 'components/landing-page/About'
import Athletes from 'components/landing-page/Athletes'
import BecomeCoach from 'components/landing-page/BecomeCoach'
import CompleteYourBooking from 'components/landing-page/CompleteYourBooking'
import Footer from 'components/landing-page/Footer'
import Hero from 'components/landing-page/Hero'
import HowItWorks from 'components/landing-page/HowItWorks'
import PickASlot from 'components/landing-page/PickASlot'
import Programs from 'components/landing-page/Programs'
import StayTuned from 'components/landing-page/StayTuned'
import NavbarLayout from 'components/navbar/NavbarLayout'

const LandingPage = ({
  athletes,
  athleteDatasources,
}: InferGetServerSidePropsType<typeof getServerSideProps>) => (
  <NavbarLayout
    className="bg-brand-slate !border-b-0"
    notificationBar={(athleteDatasources as NotificationBarQuery).notificationBar?.items}
  >
    <div className="relative flex flex-col overflow-hidden">
      <Head>
        <title>
          Book Local College Athletes for Position Specific Training. Every sport. Every position. -
          The Offseason
        </title>
        <meta
          content="Book 1:1 position specific sports training from current college athletes who excel in their sport. Open to K-12 athletes."
          name="description"
        />
        <meta
          content="Book Local College Athletes for Position Specific Training. Every sport. Every position. - The Offseason"
          property="og:title"
        />
        <meta
          content="Book 1:1 position specific sports training from current college athletes who excel in their sport. Open to K-12 athletes."
          property="og:description"
        />
        <meta property="og:image" content="/images/og-image.webp" />
        <meta property="og:image:type" content="image/webp" />
        <meta property="twitter:image" content="/images/og-image.webp" />
        <meta property="twitter:image:type" content="image/webp" />
        <meta
          content="Book Local College Athletes for Position Specific Training. Every sport. Every position. - The Offseason"
          property="twitter:title"
        />
        <meta
          content="Book 1:1 position specific sports training from current college athletes who excel in their sport. Open to K-12 athletes."
          property="twitter:description"
        />
        <meta property="og:type" content="website" />
        <meta content="summary_large_image" name="twitter:card" />
      </Head>
      <Hero />
      <About />
      <Programs />
      <Athletes athletes={athletes} athleteDatasources={athleteDatasources} />
      <HowItWorks />
      <PickASlot />
      <CompleteYourBooking />
      <BecomeCoach />
      <StayTuned />
      <Footer />
    </div>
  </NavbarLayout>
)

export const getServerSideProps: GetServerSideProps = (async (context) => {
  const athletes = await getFeaturedAthletes({
    limit: 4,
  })
  const session = await getSession(context)

  const athleteDatasources = await getAthleteDatasources()

  return {
    props: {
      session,
      athletes: athletes.items,
      athleteDatasources,
    },
  }
}) satisfies GetServerSideProps<{ athletes: Athlete[] }>

export default LandingPage

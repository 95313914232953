import { ReactNode } from 'react'

type FeatureCardProps = {
  icon: ReactNode
  title: string
  description: string
}

const FeatureCard = ({ icon, title, description }: FeatureCardProps) => (
  <div className="rounded-2xl bg-slate-900 flex-auto basis-1/4 lg:w-[540px] lg:box-content w-full overflow-hidden shrink-0 flex flex-col py-10 px-4 box-border">
    <div className="flex flex-col items-center justify-start gap-6">
      {icon}
      <div className="flex flex-col items-center justify-start gap-[8px]">
        <div className="relative leading-normal uppercase font-medium text-[28px] text-white">
          {title}
        </div>
        <div className="self-stretch flex flex-row items-center justify-center text-xl text-slate-300 p-2">
          <div className="flex-1 relative leading-normal text-center">{description}</div>
        </div>
      </div>
    </div>
  </div>
)

export default FeatureCard

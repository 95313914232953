import React from 'react'
import { cn } from 'lib/utils'
import { UsersIcon } from 'components/icons'
import { AmericanBallIcon } from 'components/icons/AmericanBallIcon'
import { UsersGroupIcon } from 'components/icons/UsersGroupIcon'
import BackgroundCaption from 'components/landing-page/BackgroundCaption'
import FeatureCard from 'components/landing-page/FeatureCard'
import Heading from 'components/landing-page/Heading'

const Programs = () => (
  <div className="bg-brand-slate relative flex flex-col items-center justify-center w-full xl:pt-[224px] xl:pb-[104px] md:px-16 sm:px-12 sm:py-[168px] px-8 py-16 overflow-visible">
    <div className="flex flex-col items-center justify-start gap-[32px] z-[0] w-full">
      <div className="shrink-0 flex flex-row items-center justify-center p-2">
        <Heading className="2xl:text-[52px]" textClassName="text-center">
          Athlete-centric programs
        </Heading>
      </div>
      <div className="shrink-0 flex xl:flex-row flex-col items-center justify-center gap-[56px] w-full max-w-[1240px]">
        <FeatureCard
          icon={<UsersIcon className="text-brand-mint" width="66" height="66" />}
          title="1-on-1"
          description="Personalized training for dedicated attention and customized skill advancement."
        />
        <FeatureCard
          icon={<UsersGroupIcon className="text-brand-mint" width="66" height="66" />}
          title="GROUP TRAINING"
          description="Collaborative sessions to elevate team dynamics and competitive skills."
        />
        <FeatureCard
          icon={<AmericanBallIcon className="text-brand-mint" />}
          title="CLINICS"
          description="Engaging, large-scale sessions for comprehensive athletic development and
          community building."
        />
      </div>
    </div>
    <BackgroundCaption
      className={cn(
        'z-[1] xl:text-[190px] lg:text-[116px] sm:text-[92px] text-[47px]',
        '2xl:-top-12 2xl:-left-0 lg:-top-24 -top-4 md:right-12 sm:-right-12 right-9',
      )}
    >
      PROGRAMS
    </BackgroundCaption>
  </div>
)

export default Programs

import React from 'react'
import { cn } from 'lib/utils'
import Image from 'next/image'
import Link from 'next/link'
import { Route } from 'constants/common/routes'
import BackgroundCaption from 'components/landing-page/BackgroundCaption'
import Heading from 'components/landing-page/Heading'
import { buttonVariants } from 'components/ui/button'

const BecomeCoach = () => (
  <div className="relative flex-1 flex flex-col items-center justify-center pb-[72px] py-[88px] lg:px-24 md:px-16 sm:px-12 px-8 bg-brand-slate">
    <div className="relative self-center flex xl:flex-row-reverse flex-col items-center justify-center w-full xl:gap-12 max-w-[1280px]">
      <div className="xl:absolute relative right-[0px] max-xl:mb-10 w-full 2xl:max-w-[630px] xl:max-w-[580px] xl:h-[624px] lg:h-[629px] md:h-[480px] sm:h-[600px] h-[370px]">
        <Image
          fill
          src="/images/become-coach.png"
          className="md:object-contain object-cover"
          alt="Become a coach poster"
        />
      </div>
      <div className="flex flex-col gap-12 lg:px-12 flex-1 z-20">
        <div className="flex flex-col gap-4">
          <Heading>Become a coach</Heading>

          <div className="flex flex-col items-start justify-start text-left text-xl gap-2 xl:max-w-[540px] lg:max-w-[738px]">
            <div className="text-brand-mint">
              Coaching Eligibility: College Athlete, Recent College Grad, Committed High School
              Athlete
            </div>
            <div className="flex-1 relative leading-normal text-slate-300 xl:max-w-[500px]">
              Unlock your coaching potential and inspire the next generation of athletes. Join The
              Offseason as a coach to guide and shape young talents by helping them reach new
              heights in their athletic journey.
            </div>
          </div>
        </div>
        <Link
          className={cn(
            buttonVariants({ colorType: 'mint', size: '2xl' }),
            'self-start uppercase text-[18px] font-bold',
          )}
          href={Route.CreateAccount}
        >
          Become a coach
        </Link>
      </div>
    </div>
    <BackgroundCaption
      className={cn(
        '2xl:text-[112px] lg:text-[96px] md:text-[70px] sm:text-[64px] text-[48px]',
        'xl:left-0 xl:-bottom-28 lg:-bottom-12 -bottom-4 right-4 ',
      )}
    >
      AIM HIGH
    </BackgroundCaption>
  </div>
)

export default BecomeCoach

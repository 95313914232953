import React from 'react'
import { cn } from 'lib/utils'
import Image from 'next/image'
import BackgroundCaption from 'components/landing-page/BackgroundCaption'
import Heading from 'components/landing-page/Heading'

const PickASlot = () => (
  <div className="z-10 relative flex-1 flex flex-col items-start justify-center pt-[88px] pb-[68px] lg:px-24 md:px-16 sm:px-12 px-8 text-brand-mint bg-brand-slate">
    <div className="self-stretch flex xl:flex-row-reverse flex-col items-center justify-center">
      <div className="max-xl:mb-10 w-full max-w-[600px] relative sm:h-[448px] h-[295px] xs:-translate-x-3">
        <Image fill src="/images/pick-a-slot.png" className="object-contain" alt="Calendar view" />
      </div>
      <div className="flex flex-col gap-4 lg:px-12">
        <Heading prefix="2.">Pick a slot</Heading>
        <div className="flex flex-row items-center justify-center text-left text-xl text-slate-300">
          <div className="flex-1 relative leading-normal xl:max-w-[584px] lg:max-w-[738px]">
            Choose a date and time that fits your schedule and select between a personalized
            one-on-one session or a group training experience.
          </div>
        </div>
      </div>
    </div>
    <BackgroundCaption
      className={cn(
        'xl:text-[172px] lg:text-[132px] md:text-[104px] sm:text-[92px] text-[72px]',
        '2xl:-bottom-20 xl:-bottom-20 lg:-bottom-32 sm:-bottom-24 -bottom-16 left-4',
      )}
    >
      RISE UP
    </BackgroundCaption>
  </div>
)

export default PickASlot

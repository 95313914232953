import React, { PropsWithChildren } from 'react'
import { cn } from 'lib/utils'

type BackgroundCaptionProps = {
  className?: string
}

const BackgroundCaption = ({ className, children }: PropsWithChildren<BackgroundCaptionProps>) => (
  <div
    className={cn(
      'absolute z-10 leading-normal [-webkit-text-stroke:1.5px_rgba(73,_255,_210,_0.6)] opacity-[0.4] text-transparent font-prohibition whitespace-nowrap uppercase',
      className,
    )}
  >
    {children}
  </div>
)

export default BackgroundCaption

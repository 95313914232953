import { SVGProps } from 'react'

export const AmericanBallIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="66"
    height="65"
    viewBox="0 0 66 65"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M41.0725 24.2188L24.9269 40.3644M27.6178 32.2916L32.9997 37.6735M32.9997 26.9097L38.3816 32.2916M22.236 56.51C22.236 52.9416 20.8184 49.5194 18.2952 46.9961C15.7719 44.4729 12.3497 43.0553 8.78125 43.0553"
      stroke="currentColor"
      strokeWidth="3.63"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M43.7635 8.07324C24.4425 8.07324 8.78125 23.7345 8.78125 43.0555C8.78125 46.6239 10.1988 50.0461 12.722 52.5694C15.2453 55.0926 18.6675 56.5102 22.236 56.5102C41.5569 56.5102 57.2182 40.8489 57.2182 21.5279C57.2182 17.9595 55.8006 14.5373 53.2774 12.014C50.7541 9.49079 47.3319 8.07324 43.7635 8.07324Z"
      stroke="currentColor"
      strokeWidth="3.63"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M43.7637 8.07324C43.7637 11.6417 45.1812 15.0639 47.7045 17.5872C50.2277 20.1104 53.65 21.5279 57.2184 21.5279"
      stroke="currentColor"
      strokeWidth="3.63"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

import * as React from 'react'
import { useMedia } from 'react-use'
import { cn } from 'lib/utils'
import Image from 'next/image'
import Link from 'next/link'
import { Route } from 'constants/common/routes'
import Heading from 'components/landing-page/Heading'
import { buttonVariants } from 'components/ui/button'

export default function About() {
  const isXl = useMedia('(min-width: 1280px)', false)
  return (
    <div className="flex justify-center items-center bg-slate-950 xl:py-[102px] xl:px-24 lg:py-20 lg:px-24 md:py-20 md:px-16 sm:py-20 sm:px-12 py-12 px-8">
      <div className="flex justify-center items-center xl:flex-row-reverse flex-col xl:max-w-[1344px] xl:gap-5 gap-0">
        <div className="flex flex-col items-stretch flex-1">
          <div className="justify-center flex flex-col items-start lg:px-12 2xl:max-w-[740px]">
            <Heading textClassName="font-extrabold">
              WE CONNECT K-12 STUDENT ATHLETES WITH COLLEGE ATHLETES
            </Heading>
            <div className="text-slate-300 text-xl leading-normal self-stretch mt-4 max-md:max-w-full">
              Our platform offers a unique opportunity for K-12 students to receive personalized,
              position-specific training from current college athletes who excel in their sport.
              This isn&apos;t just about improving skills; it&apos;s about mentorship, learning from
              those who are living the journey, and gaining insights that go beyond the game.
            </div>
            <Link
              href={Route.Athletes}
              className={cn(
                buttonVariants({ colorType: 'mint', size: '2xl' }),
                'uppercase mt-8 text-[18px] font-bold',
              )}
            >
              Book athlete
            </Link>
          </div>
        </div>
        <div className="flex flex-col">
          <Image
            className="object-contain"
            width={isXl ? 498 : 600}
            height={480}
            src="/images/about.png"
            alt="Bookings overview"
          />
        </div>
      </div>
    </div>
  )
}

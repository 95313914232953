import React from 'react'
import { AthleteDatasourcesQuery } from 'api/integrations/storyblok/generated/cms'
import { Athlete } from 'apollo/generated/graphqlClient'
import { cn } from 'lib/utils'
import Link from 'next/link'
import { Route } from 'constants/common/routes'
import { AthleteCard } from 'components/athletes/AthleteCard'
import Heading from 'components/landing-page/Heading'
import { buttonVariants } from 'components/ui/button'

type AthletesProps = {
  athletes: Athlete[]
  athleteDatasources: AthleteDatasourcesQuery
}

const Athletes = ({ athletes, athleteDatasources }: AthletesProps) => (
  <div className="bg-slate-900 w-full flex flex-col items-center justify-start lg:px-24 md:px-16 sm:px-12 py-20 px-8 box-border text-slate-400">
    <div className="flex flex-col items-center justify-center">
      <div className="leading-normal uppercase text-slate-500 font-bold lg:text-2xl text-xl text-center p-2">
        Featured athletes
      </div>
      <Heading className="p-2" textClassName="text-white text-center xl:text-[52px]">
        Choose from Elite Athletes
      </Heading>
    </div>
    <div className="grid w-full xl:grid-cols-4 xl:max-w-[1280px] lg:max-w-[630px] max-w-[1280px] lg:px-4 my-16 sm:grid-cols-2 grid-cols-1 items-stretch justify-center gap-6">
      {athletes.map((athlete) => (
        <AthleteCard
          athleteDatasources={athleteDatasources}
          key={athlete.id}
          athlete={{ ...athlete, specifiedLocation: athlete.specifiedLocation }}
        />
      ))}
    </div>
    <Link
      className={cn(
        buttonVariants({ variant: 'outline', size: '2xl' }),
        'uppercase px-10 text-[18px] leading-normal w-[192px]',
      )}
      href={Route.Athletes}
    >
      Show more
    </Link>
  </div>
)

export default Athletes

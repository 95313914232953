import React, { PropsWithChildren } from 'react'
import { cn } from 'lib/utils'

type HeadingProps = {
  className?: string
  textClassName?: string
  prefixClassName?: string
  prefix?: string
}

const Heading = ({
  className,
  textClassName,
  prefix,
  prefixClassName,
  children,
}: PropsWithChildren<HeadingProps>) => (
  <div
    className={cn(
      'flex flex-row items-start justify-start gap-[8px] font-black 2xl:text-[48px] md:text-4xl text-3xl font-prohibition !leading-[normal]',
      className,
    )}
  >
    {prefix && (
      <div className={cn('relative uppercase text-brand-mint leading-normal', prefixClassName)}>
        {prefix}
      </div>
    )}
    <div
      className={cn('flex-1 relative uppercase text-white text-left leading-normal', textClassName)}
    >
      {children}
    </div>
  </div>
)

export default Heading

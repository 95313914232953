import React from 'react'
import { cn } from 'lib/utils'
import Image from 'next/image'
import Link from 'next/link'
import { Route } from 'constants/common/routes'
import BackgroundCaption from 'components/landing-page/BackgroundCaption'
import Heading from 'components/landing-page/Heading'
import { buttonVariants } from 'components/ui/button'

const CompleteYourBooking = () => (
  <div className="relative flex-1 flex flex-col items-center justify-center sm:pb-[240px] pb-[128px] py-[88px] lg:px-24 md:px-16 sm:px-12 px-8 text-brand-mint bg-brand-slate overflow-hidden">
    <div className="self-center flex xl:flex-row flex-col items-center justify-center w-full">
      <div className="max-xl:mb-10 w-full max-w-[600px] relative sm:h-[426px] h-[289px]">
        <Image
          fill
          src="/images/complete-your-booking.png"
          className="object-contain"
          alt="Complete your booking view"
        />
      </div>
      <div className="flex flex-col gap-12 lg:px-12">
        <div className="flex flex-col gap-4">
          <Heading prefix="3.">Complete your booking</Heading>
          <div className="flex flex-row items-center justify-start text-left text-xl text-slate-300">
            <div className="flex-1 relative leading-normal xl:max-w-[584px] lg:max-w-[738px]">
              Enter your contact details, review your session summary, and proceed to payment to
              secure your slot. Get ready to transform your training with just a few clicks!
            </div>
          </div>
        </div>
        <Link
          className={cn(
            buttonVariants({ colorType: 'mint', size: '2xl' }),
            'self-start uppercase text-[18px] font-bold',
          )}
          href={Route.Athletes}
        >
          Book athlete
        </Link>
      </div>
    </div>
    <BackgroundCaption
      className={cn(
        '2xl:text-[160px] lg:text-[125px] sm:text-[93px] text-[67px]',
        '2xl:-right-1 md:right-[20%] sm:right-4 right-2 xl:bottom-12 bottom-0',
      )}
    >
      Push ahead
    </BackgroundCaption>
  </div>
)

export default CompleteYourBooking
